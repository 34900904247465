
import { getItem, postItem } from "../../../api/api-methods";
import { createFormDataFromObject } from "./dataUtils";

export const fetchPostImage = async (imageData, typeImage) => {
  const URL = '/app-personas/imagen'
  try {
    const imageName = imageData.name;
    const formData = createFormDataFromObject({
      nombre_imagen: imageName,
      imagen: imageData,
      tipo_imagen: typeImage,
    });

    const imageResponse = await postItem(URL, formData);
    if (!imageResponse || !imageResponse.id) {
      throw new Error('Image response is invalid');
    }

    return imageResponse;
  } catch (error) {
    console.error('Error al crear o enviar la imagen:', error);
    throw error; 
  }
};

export const fetchGetImage = async (imageId) => {
  const URL = `/app-personas/imagen/${imageId}`
  try {
    const imageResponse = await getItem(URL);
    if (!imageResponse || !imageResponse.id) {
      throw new Error('Image response is invalid');
    }
    return imageResponse;
  } catch (error) {
    console.error('Error al crear o enviar la imagen:', error);
    throw error; 
  }
};