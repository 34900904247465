import { postItem } from "../../../api/api-methods";

export const fetchPostDebit = async (debit, student, institution,  user) => {
  const URL = '/app-administracion/adeudos'
  try {
    const res = await postItem(URL, {
      ...debit,
      alumno: student,
      moneda: debit.moneda.id,
      penalizacion: debit.penalizacion.id,
      institucion_educativa: institution,
      descuento_pronto_pago: debit.descuento_pronto_pago.id,
      estatus: debit.estatus.id,
      autor: user,
    })
    return { res, ok: true }
  } catch (error) {
    return { ok: false, message: error?.toString() }
  }
}