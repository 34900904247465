import { getItem, postItem } from "../../../api/api-methods"

export const saleUtilsMixing = {
  methods: {
    async getOrCreateMovementType(name, user) {
      try {
        const movementType = await getItem(`/app-inventarios/filters/mv-movimiento-inventario?dato=${name}&estatus_sistema=true`);
        if (movementType.results.length > 0) {
          return movementType.results[0];
        } else {
          const createdMovementType = await postItem('/app-inventarios/tipo-movimiento-inventario', {
            dato: name,
            autor: user,
            estatus_sistema: true
          });
          return createdMovementType;
        }
      } catch (error) {
        console.error('Error in getOrCreateMovementType:', error);
      }
    },
    async getOrCreateMovementReason(name, user) {
      try {
        const movementReason = await getItem(`/app-inventarios/filters/motivo-movimiento-inventario?dato=${name}&estatus_sistema=true`);
        if (movementReason.results.length > 0) {
          return movementReason.results[0];
        } else {
          const createdMovementReason = await postItem('/app-inventarios/motivo-movimiento-inventario', {
            dato: name,
            autor: user,
            estatus_sistema: true
          });
          return createdMovementReason;
        }
      } catch (error) {
        console.error('Error in getOrCreateMovementReason:', error);
      }
    },
    async getOrCreateIncomeCategory(name, user, institution) {
      try {
        const incomeCategories = await getItem(`app-administracion/filters/categoria-ingreso?dato=${name}&institucion_educativa=${institution}&estatus_sistema=true`);
        if (incomeCategories.results.length > 0) {
          return incomeCategories.results[0];
        } else {
          const createdIncomeCategories = await postItem('app-administracion/categoria-ingreso', {
            dato: name,
            autor: user,
            institucion_educativa: institution,
            estatus_sistema: true
          })
          return createdIncomeCategories
        }
      } catch (error) {
        console.error('Error in getIncomeCategory:', error);
        throw error;
      }
    },
    async getOrCreateIncomeStatus(name, user, institution) {
      try {
        const incomeStatus = await getItem(`app-administracion/filters/estatus-ingreso?dato=${name}&institucion_educativa=${institution}&estatus_sistema=true`);
        if (incomeStatus.results.length > 0) {
          return incomeStatus.results[0];
        } else {
          const createdIncomeStatus = await postItem('app-administracion/estatus-ingreso', {
            dato: name,
            autor: user,
            institucion_educativa: institution,
            estatus_sistema: true,
          })
          return createdIncomeStatus
        }
      } catch (error) {
        console.error('Error in getIncomeStatus:', error);
        throw error;
      }
    },
    async getOrCreatePenalties(name, user, institution) {
      try {
        const penalties = await getItem(`app-administracion/filters/penalizacion?nombre=${name}&institucion_educativa=${institution}&estatus_sistema=true`);
        if (penalties.results.length > 0) {
          return penalties.results[0];
        } else {
          const createdPenalties = await postItem('app-administracion/penalizacion', {
            nombre: name,
            descripcion: 'Description',
            monto: 0,
            autor: user,
            institucion_educativa: institution,
            estatus_sistema: true
          })
          return createdPenalties
        }
      } catch (error) {
        console.error('Error in getPenalties:', error);
        throw error;
      }
    },
    async getOrCreateDebtStatus(name, user, institution) {
      try {
        const debtStatus = await getItem(`/app-administracion/filters/estatus-adeudos?dato=${name}&institucion_educativa=${institution}&estatus_sistema=true`)
        if (debtStatus.results.length > 0) {
          return debtStatus.results[0]
        } else {
          const cratedDebtStatus = await postItem('/app-administracion/estatus-adeudos', {
            dato: name,
            autor: user,
            institucion_educativa: institution,
            estatus_sistema: true
          })
          return cratedDebtStatus
        }
      } catch (error) {
        console.error('Error in getOrCreateDebtStatus:', error);
        throw error;
      }
    },
    async getOrCreatePromptPayDiscounts(name, user, institution) {
      try {
        const promptPayDiscounts = await getItem(`/app-administracion/filters/descuento-pronto-pago?nombre_descuento=${name}&institucion_educativa=${institution}&estatus_sistema=true`)
        if (promptPayDiscounts.results.length > 0) {
          return promptPayDiscounts.results[0]
        } else {
          const cratedPromptPayDiscounts = await postItem('app-administracion/descuento-pronto-pago', {
            nombre_descuento: name,
            institucion_educativa: institution,
            porcentaje_descuento: 10,
            fecha_inicio: '2023-11-17',
            fecha_fin: '2025-11-17',
            autor: user,
            estatus_sistema: true
          })
          return cratedPromptPayDiscounts
        }
      } catch (error) {
        console.error('Error in getOrCreatePromptPayDiscounts:', error);
        throw error;
      }
    },

    async getOrCreateShippingTypes(name, user) {
      try {
        const movementReason = await getItem(`/app-ordenes/filters/mv-tipo-envio?dato=${name}&estatus_sistema=true`);
        if (movementReason.results.length > 0) {
          return movementReason.results[0];
        } else {
          const createdMovementReason = await postItem('/app-ordenes/tipo-envio', {
            dato: name,
            autor: user,
            estatus_sistema: true
          });
          return createdMovementReason;
        }
      } catch (error) {
        console.error('Error in getOrCreateShippingTypes:', error);
      }
    },

    async getOrCreatePublicCustomer(name, user, institution) {
      try {
        const studentsRes = await getItem(`app-personas/filters/alumno?institucion_educativa=${institution}&estatus_sistema=true`)
        if (studentsRes.results.length > 0) {
          return studentsRes.results[0]
        } else {
          const personalData = await postItem('app-personas/datos-personales', {
            primer_nombre: 'Publico',
            segundo_nombre: 'Publico',
            apellido_paterno: 'Publico',
            apellido_materno: 'Publico',
            fecha_nacimiento: '1998-12-12',
            edad: 10,
            genero: 1,
            numero_identidad_CURP: 'PUBLICO',
            email: 'perfil_publico@outlook.com',
            telefono_casa: '00000000',
            telefono_movil: '00000000',
            direccion: 1,
            institucion_educativa: institution,
            estatus_sistema: true,
          })
          const createdPublicCustomer = await postItem('app-personas/alumno', {
            usuario: user,
            numero_credencial_alumno: name,
            datos_personales: personalData.id,
            tutores: [1],
            institucion_educativa: institution,
            estatus_sistema: true,
          })
          return createdPublicCustomer
        }
      } catch (error) {
        console.error('Error in getOrCreatePromptPayDiscounts:', error);
        throw error;
      }
    },
  }
}