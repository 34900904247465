import { getItem, postItem, putItem } from "../../../api/api-methods"

export const fetchPostOrder = async (order) => {
  const URL = '/app-ordenes/orden'
  try {
    const res = await postItem(URL, order)
    return { res, ok: true }
  } catch (error) {
    return { ok: false, message: error?.toString() }
  }
}

export const fetchPutOrder = async ( order) => {
  const URL = `/app-ordenes/orden/${order.id}`
  try {
    const res = await putItem(URL, {
      ...order,
      alumno: order.alumno.id,
      autor: order.autor.id,
      datos_facturacion: order.datos_facturacion.id,
      direccion_envio: order.direccion_envio.id,
      estatus_orden: order.estatus_orden.id,
      institucion_educativa: order.institucion_educativa.id,
      moneda: order.moneda.id,
      tipo_envio: order.tipo_envio.id,
    })
    return { res, ok: true }
  } catch (error) {
    return { ok: false, message: error?.toString() }
  }
}

export const fetchPostOrderDetail = async (orderDetail) => {
  const URL = '/app-ordenes/detalle-orden'
  try {
    const res = await postItem(URL, orderDetail)
    return { res, ok: true }
  } catch (error) {
    return { ok: false, message: error?.toString() }
  }
}
export const fetchGetOrder = async (order) => {
  const URL = `/app-ordenes/orden/${order}`
  try {
    const res = await getItem(URL)
    return { res, ok: true }
  } catch (error) {
    return { ok: false, message: error?.toString() }
  }
}
export const fetchPostCancellationOrder = async (order) => {
  const URL = '/app-ordenes/cancelacion-orden'
  try {
    const res = await postItem(URL, order)
    if(res.e)
      return { res, ok: false }
    return { res, ok: true }
  } catch (error) {
    return { ok: false, message: error?.toString() }
  }
}