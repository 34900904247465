import { postItem } from "../../../api/api-methods"

export const fetchPostIncome = async (income) => {
  const URL = '/app-administracion/ingreso'
  try {
    const res = await postItem(URL, income)
    return { res, ok: true }
  } catch (error) {
    return { ok: false, message: error?.toString() }
  }
}