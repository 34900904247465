export const BASE_INVENTORY_MOVEMENTS = {
  MOVEMENT_REASON: 'Venta',
  MOVEMENT_TYPE: 'Salida'
}

export const BASE_CREATE_INCOME = {
  VENTA: 'Venta',
  PAGADO: 'Pagado',
  PENALIZACION: 'Pago tardio'
}

export const BASE_CREATE_DEBT = {
  DEBIT_ESTATUS: 'Pendiente de pago',
  PENALIZACION: 'Pago tardio',
  DESCUENTO: 'Pronto pago cargo',
}

export const CREDENTIAL_PUBLIC = {
  CREDENTIAL: 'PUBLICO'
}

export const BASE_CREATE_NEW_INVENTORY = {
  MOVEMENT_REASON: 'Compra',
  MOVEMENT_TYPE: 'Entrada'
}
export const BASE_CANCELLATION_ORDER = {
  MOVEMENT_REASON: 'Devolución',
  MOVEMENT_TYPE: 'Entrada'
}