import { getItem, postItem, putItem } from "../../../api/api-methods"

export const fetchPutInventory = async (inventory) => {
  const URL = `/app-inventarios/inventario/${inventory.id}`
  try {
    const res = await putItem(URL, inventory)
    if (res.e)
      return { res, ok: false }
    return { res, ok: true }
  } catch (error) {
    return { ok: false, message: error?.toString() }
  }
}

export const fetchPostMovementInventory = async (movementInventory) => {
  const URL = `/app-inventarios/helpers/crear-movimiento-inventario`
  try {
    const res = await postItem(URL, movementInventory)
    return { res, ok: true }
  } catch (error) {
    return { ok: false, message: error?.toString() }
  }
}

export const fetchGetInventory = async (inventory) => {
  const URL = `/app-inventarios/inventario/${inventory}`
  try {
    const res = await getItem(URL)
    return { res, ok: true }
  } catch (error) {
    return { ok: false, message: error?.toString() }
  }
}